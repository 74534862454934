
















import Vue from "vue";
import Component from "vue-class-component";
import { AUTH_CAROUSEL_IMAGES } from "@sportango/site-configuration/out/styles";

@Component({
  name: "auth-carousel"
})
export default class AuthCarousel extends Vue {
  private readonly AUTH_CAROUSEL_IMAGES = AUTH_CAROUSEL_IMAGES;
}
