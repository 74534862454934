





















































































import Vue from "vue";
import Component from "vue-class-component";
import Logo from "@sportango/site-configuration/out/src/assets/logo.svg";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import AuthCarousel from "@/components/users/AuthCarousel.vue";

@Component({
  name: "auth",
  components: {
    AuthCarousel,
    Logo,
    LottieAnimation
  }
})
export default class Auth extends Vue {
  private activeTab: string;
  private isFinished: boolean;
  isResetSent = false;
  constructor() {
    super();
    this.activeTab = this.$route.path;
    this.isFinished = false;
  }

  get showRegisterPage(): boolean {
    return !this.$store.getters.featureFlags["direct-registration"];
  }
}
